import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();
const intro_scroll = document.getElementsByClassName('Intro__scroll')[0];
intro_scroll &&
  intro_scroll.addEventListener('click', function () {
    const header = document.getElementsByTagName('header')[0];
    window.scroll({
      top: window.innerHeight - header.clientHeight,
      left: 0,
      behavior: 'smooth',
    });
  });
