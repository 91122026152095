import '../lib/external/scroll-button';
import '../lib/external/auto-messages';
import '../styles/external/global.less';

const elements = document.getElementsByClassName('open_chat');

function openChat() {
  Quickley('open');
}

for (const el of elements) {
  el.addEventListener('click', openChat);
}
