import { IS_MOBILE } from '../constants';

const STORAGE_FIELD = 'chat_auto_messages';
const AUTO_MESSAGES = [
  {
    uri: '/ru',
    message: 'Привет 👋  \n Нужна помощь? Я могу ответить на все ваши вопросы.',
    once: true,
    timeout: 20 * 1000,
  },
  {
    uri: '/ru/pricing',
    message: 'Привет! У вас есть вопросы по тарифам Quickley? Давайте я помогу разобраться.',
    once: false,
    timeout: 15 * 1000,
  },
  {
    uri: '/ru/instagram',
    message: 'Привет! Давайте расскажу вам больше об интеграции с Instagram?',
    once: true,
    timeout: 15 * 1000,
  },
  {
    uri: '/ru/whatsapp',
    message: 'Привет! У вас есть вопросы? Давайте обсудим интеграцию WhatsApp для вашего бизнеса.',
    once: false,
    timeout: 15 * 1000,
  },
  {
    uri: '/whatsapp',
    message:
      'Hey 👋 If you need any help, just shoot us a message or schedule a call https://calendly.com/quickley/whatsapp-demo',
    once: false,
    timeout: 15 * 1000,
  },
  {
    uri: '/ru/live-chat',
    message: 'Привет! Хотите настроить такие же сообщения на своём сайте?',
    once: false,
    timeout: 15 * 1000,
  },
];

if (!IS_MOBILE) {
  const chat_auto_messages = JSON.parse(localStorage.getItem(STORAGE_FIELD) || '[]');
  const uri = location.pathname;

  AUTO_MESSAGES.forEach((am) => {
    if (uri === am.uri && (!am.once || chat_auto_messages.indexOf(am.uri) < 0)) {
      setTimeout(() => {
        if (am.once) {
          localStorage.setItem(STORAGE_FIELD, JSON.stringify([...chat_auto_messages, am.uri]));
        }
        Quickley('sendAutoMessage', am.message);
      }, am.timeout);
    }
  });
}
